/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/2/2021     bakul.banthia         Created
 *
 */

@import url("https://fonts.googleapis.com/css2?family=Lato");
@import url("https://fonts.cdnfonts.com/css/texta");

#root {
    height: 100%;
    position: relative;

    .index {
        height: 100%;
    }
}

html {
    width: 100%;
    height: 100%;
}

body {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-family: "Lato", "Helvetica", "Arial", sans-serif;
    margin: 0px;
    width: 100%;
    height: 100%;
}

.fade-transition-enter {
    opacity: 0;
}

.fade-transition-enter-active {
    opacity: 1;
    transition: opacity 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53) !important;
}

.fade-transition-exit {
    opacity: 1;
}

.fade-transition-exit-active {
    opacity: 0;
    transition: opacity 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) !important;
}

.pf-c-text-input-group__text-input {
    padding: 6px 8px 6px 32px !important;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@font-face {
    font-family: "TextaLight"; /*Can be any text*/
    src: local("TextaLight"), url("../fonts/TextaLight.ttf") format("truetype");
}

@font-face {
    font-family: "TextaMedium"; /*Can be any text*/
    src: local("TextaMedium"), url("../fonts/TextaMedium.ttf") format("truetype");
}

@font-face {
    font-family: "Texta"; /*Can be any text*/
    src: local("TextaRegular"), url("../fonts/TextaRegular.ttf") format("truetype");
}

@font-face {
    font-family: "TextaBold"; /*Can be any text*/
    src: local("TextaBold"), url("../fonts/TextaBold.ttf") format("truetype");
}

@font-face {
    font-family: "TextaBlack"; /*Can be any text*/
    src: local("TextaBlack"), url("../fonts/TextaBlack.ttf") format("truetype");
}

@font-face {
    font-family: "TextaHeavy"; /*Can be any text*/
    src: local("TextaHeavy"), url("../fonts/TextaHeavy.ttf") format("truetype");
}
